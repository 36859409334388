.service_alert_container_main {
    position: fixed;
    top: 15px;
    right: 45px;
    width: 300px;
    height: auto;
    min-height: 150px;
    background-color: tomato;
    color: white;
    z-index: 9999;
    padding: 15px;
}
.service_alert_container_main.success{
    background: green;
}
.alert_container__header{
    text-align: right;
    margin-bottom: 5px;
}
.alert_container__header .close-modal{
    padding: 0;
    width: 20px;
    height: 20px;
}
.alert_container__message{
    line-height: 18px;
    font-size: 16px;
}