@import "./components/InputFields/InputForm/InputForm.css";
@import "./pages/SettingsFirm/settings.css";

:root {
    --red: #df5c4e;
    --green: #56c080;
    --blue: #4262ff;
    --orange: #fac711;
    --border: #d9d8e1;
    --gray-violet: #605d81;
}

html {
    height: -webkit-fill-available;
    overflow: hidden;
    overflow-wrap: break-word;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    overflow: hidden;
    /*white-space: normal; !* Отключить сохранение неразрывных пробелов *!*/
    /*word-break: break-word; !* Разбивать слова при необходимости *!*/
    overflow-wrap: break-word; /* Альтернатива для переноса слов */
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.cursor-pointer {
    cursor: pointer !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.form-input-checkbox-label {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
}

.form-input-checkbox {
    display: none;
}

.form-input-checkbox-label:before {
    content: '';
    display: block;
    width: 19px;
    flex: 0 0 19px;
    height: 19px;
    border: 1px solid #d9d8e1;
    border-radius: 5px;
    margin-right: 5px;
}

.form-input-checkbox {
    display: none;
}

.form-input-checkbox:checked + span:after {
    display: block;
    content: '✔';
    color: black;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: calc(50% - 40px);
}

.goods-connected_band .form-input-checkbox:checked + span:after {
    left: 5px !important;
}

.form-field .form-input-checkbox-label {
    justify-content: flex-start;
    margin: 15px 0;
}

.form-field .form-input-checkbox-label span {
    margin-left: 9px;
}

.form-field .form-input-checkbox:checked + span:after {
    left: 4px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

svg.back-arrow {
    margin-right: 8px;
}


.price-list ul li {
    list-style: revert;
}

.price-list ul {
    list-style: revert;
    margin: 15px 0px;
}

.price-list ul li {
    margin-bottom: 8px;
}

/*TABLE*/

.info-exel {
    position: relative;
}

.info-exel .info-exel-content {
    position: absolute;
    background: white;
    z-index: 3;
    border: 1px solid #d9d8e1;
    padding: 0px 10px;
    max-width: 320px;
    top: 0px;
    /* display: none; */
    border-radius: 8px;
    left: 0;
    /*display: block;*/
    right: 0px;
    display: none;
}

.info-exel a:hover + .info-exel-content {
    display: block;
}

.info-exel .info-exel-content ul {
    padding-left: 0px;
}

.info-exel .info-exel-content ul li {
    display: flex;
    align-items: center;
}

.info-exel .info-exel-content ul li svg {
    margin-right: 5px;
}

.info-exel .info-icon:hover + .info-exel-content {
    /*display: block;*/
}

tr.no-Focus {
    background-color: white !important;
}

.warning-modal__btns .btn {
    padding: 10px !important;
}


.combine-part-list .item {
    display: flex;
    position: relative;
    margin: 15px 0px;
}

.combine-part-list .item .title {
    font-size: 14px;
    font-weight: 500;
}


.combine-part-list .item {
    display: flex;
}

.combine-prev-step {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.combine-prev-step .arrow-top {
    transform: rotate(0deg);
    zoom: 0.9;
}

.combine-part-list .change-position {
    margin-right: 15px;
}


.combine-material_info {
    border: 1px solid #274cff5e;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 15px;
}

.combine-material_info h5 {
    margin: 0px;
}

.combine-material_info ul {
    list-style: circle;
}


.combine-material_info ul li {
    list-style: decimal;
    font-size: 14px;
}

.combine-material_info-head {
    display: grid;
    grid-template-columns: calc(100% - 35px) 35px;
    align-items: flex-start;
}

.combine-material_info-head button {
    border: none;
    background: transparent;
}

.combine-material_info-head.close h5 {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
    overflow: hidden;
}

/*style template paz*/
.template-paz-container {
    padding: 10px;
}

@media (min-width: 1200px) {
    .template-paz-container {
        padding: 24px;
    }
}

.template-paz-container .panel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.template-paz-container .panel > button.btn {
    width: max-content;
    padding: 5px 8px !important;
    font-size: 14px;
    border: none;
}

.template-paz-container .panel > * {
    margin: 5px 5px 0px 0px;
}

.template-paz__color {
    display: flex;
    align-items: center;
    position: relative;
}

.current-color {
    width: 50px;
    height: 25px;
    border: 1px solid #ddd;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 8px;
    padding: 2px;
}

.template-paz__color {
}

.template-paz__color .sketch-picker {
    position: absolute;
    right: 0;
}

img {
    max-width: 100%;
}

.filed-template-paz {
    display: grid;
    grid-template-columns: 25px 1fr;
    align-items: flex-start;
    margin-bottom: 25px;
}

.filed-template-paz .content {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;
}

/*end style template paz*/

.connection-bands-form {
    width: 100%;
}

.connection-baned__item {
    display: grid;
    grid-template-columns: 25px 1fr;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.connection-baned__item button {
    padding: 5px !important;
    font-size: 12px !important;
    text-transform: uppercase;
    width: max-content;
    max-width: 100%;
    margin-left: auto;
    margin-top: 0px;
}


.connection-baned__item p {
    margin: 0px;
    font-size: 15px;
    font-weight: 600;
}

.connection-bands-card-item .burger-menu-wrap button.burger-btn {
    margin-left: 0px;
}

.connection-bands-card-item .burger-menu-wrap {
    display: block !important;
    z-index: 9;
}

.connection-bands-card-item .burger-menu-wrap button.burger-btn .bars {
    width: 18px;
    height: 3px;
    margin-bottom: 3px;
}

.template-modal .field.filed-template-paz:not(:nth-child(4)) {
    padding-bottom: 25px;
    border-bottom: 1px solid #dddddd;
}

.settings-container .radio-list {
    display: flex;
    flex-direction: column;
}

.settings-container .radio-list label {
    margin-bottom: 8px;
}

.band-select_hem {
    margin: 10px 0px;
}

.wrap-edge-change_hem {
    margin-top: 25px;
    padding: 5px;
}

.wrap-edge-change_hem label {
    color: #e77070;
    line-height: 16px;
    font-weight: bold;
}

/*lables print modal*/

.filter-switch {
    margin: 8px 0px;
    display: flex;
    background: #edf2f6;
    border-radius: 3px;
    overflow: hidden;
    padding: 8px 0px;
    max-width: 100%;
    width: max-content;

}


.filter-switch li label {
    padding: 8px 12px;
    background: inherit;
    font-size: 16px;
    /* font-weight: bold; */
}

.filter-switch input[type="radio"] {
    display: none;
}

.filter-switch li input:checked + label {
    background: #050038;
    color: white;
}


.relative {
    position: relative;
}

.toggle-pill input[type="checkbox"] {
    display: none;
}

.toggle-pill + label {
    margin-left: 8px;
}

.toggle-pill input[type="checkbox"] + label {
    display: block;
    position: relative;
    width: 3em;
    height: 1.6em;

    /*margin-bottom: 20px;*/
    border-radius: 1em;
    background: #f3f3f3;
    box-shadow: inset 0px 0px 3px 1px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-transition: background 0.1s ease-in-out;
    transition: background 0.1s ease-in-out;
}

.toggle-pill input[type="checkbox"] + label:before {
    content: "";
    display: block;
    width: 1.2em;
    height: 1.2em;
    border-radius: 1em;
    background: #50565a;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 0.2em;
    top: 0.2em;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.toggle-pill input[type="checkbox"]:checked + label {
    background: #fff;
}

.toggle-pill input[type="checkbox"]:checked + label:before {
    box-shadow: 0 0 0 transparent;
    left: 1.6em;
    background: green;
}

.checkbox-label__input {
    position: relative;
    padding-left: 25px;
}

.checkbox-label__input label {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox-label__input input[type="checkbox"] {
    display: none;
}

.checkbox-label__input input:checked + label:after {
    content: '✔';
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 2px;
    top: 0px;
}

.checkbox-label__input label:before {
    content: "";
    display: block;
    width: 15px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 0px;
    border-radius: 3px;
    border: 1px solid #cccccc;
    cursor: pointer;
    /*background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='19' height='20' viewBox='0 0 19 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M18.125 10V16.7083C18.125 17.2167 17.9231 17.7042 17.5636 18.0636C17.2042 18.4231 16.7167 18.625 16.2083 18.625H2.79167C2.28334 18.625 1.79582 18.4231 1.43638 18.0636C1.07693 17.7042 0.875 17.2167 0.875 16.7083V3.29167C0.875 2.78334 1.07693 2.29582 1.43638 1.93638C1.79582 1.57693 2.28334 1.375 2.79167 1.375H13.3333' stroke='%239A98AE' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e ");*/
}

.icon-konfirmat {
    max-height: 30px;
    padding: 3px;

}

.select-confirmed-sinking li {
    margin: 3px 0px;
}

.select-confirmed-sinking {
    margin-right: 5px;
}

button:disabled {
    opacity: 0.8;
}

.text-center {
    text-align: center;
}
.text-right{
    text-align: right;
}
.list-none {
    list-style: none;
    margin: 0;
    padding: 0;
}

.list-none li {
    margin: 4px 0px;
    padding: 4px 8px;
    font-size: 16px;
    border-bottom: 1px solid #f3f4f8
}

.list-none li:last-child {
    margin-bottom: 0px;
    border: none;
}

.border-none {
    border: none !important;
}
.w-max-content{
    width: max-content !important;
    max-width: 100% !important;
}
.mx-auto{
    margin: 0 auto;
}

.w-100{
    width: 100% !important;
    max-width: 100% !important;
}

.cursor-pointer{
    cursor: pointer !important;
}


.text-success,
.clr-green {
    color: var(--green);
}

.fs-12 {
    font-size: 12px;
}
.text-black{
    color: black;
}

.text-primary,
.clr-blue {
    color: var(--blue)!important;
}

.text-danger,
.clr-red {
    color: var(--red) !important;
}

.text-underline {
    text-decoration: underline;
}
.text-uppercase{
    text-transform: uppercase;
}

.icon-trash:hover path {
    stroke: var(--red);
}

.text-warning {
    color: var(--orange)!important;
}
.text-secondary{
    color: var(--gray-violet)!important;
}
.resend-action, .resend-password {
    font-size: 14px;
    text-decoration: underline;
    color: var(--orange);
    display: block;
    margin: 12px 0;
    cursor: pointer;
}
.font-bold{
    font-weight: 600;
}
.font-medium{
    font-weight: 500;
}
.mr-10{
    margin-right: 10px;
}
.mb-2 {
    margin-bottom: 1.5rem !important;
}
.mt-2 {
    margin-top: 1.5rem !important;
}
.mb-1 {
    margin-bottom: 0.8rem !important;
}

.mb-0 {
    margin-bottom: 0px !important;
}
.mt-1 {
    margin-top: 0.8rem !important;
}


.m-0 {
    margin: 0 !important;
}

/*forms*/
.helper-article {
    position: relative;
    padding-right: 15px !important;
}

.helper-article .icon-question {
    position: absolute;
    width: 16px;
    right: 0;
    top: 0;
}

.comment-accordion-toggle {
    display: flex;
}

.comment-accordion-toggle svg {
    margin-left: 10px;
}

/*BUTTON*/
.btn {
    width: 100%;
    background: #4262ff;
    /*background: #3586FF;*/
    font-size: 17px;
    color: white;
    padding: 8px 8px;
    outline: none;
    box-shadow: 0 0 0 transparent;
    cursor: pointer;
    outline: transparent;
    border-radius: 3px;
    border: 1px solid #4262ff;
    /*border: 1px solid #3586FF;*/
}

@media (min-width: 768px) {
    .btn {
    }
}

.btn.btn-small,
.btn-small {
    /*border-width: 1px;*/
    /*border-style: solid;*/
    /*background: transparent;*/
    font-size: 12px;
    /*text-transform: uppercase;*/
    padding: 8px;
    border-radius: 3px;
    color: white;
    cursor: pointer;
}
.form-field.filed-error small {
    font-size: 12px;
}
.form-field.filed-error input {
    border-color: #e700134d;
}
.btn-danger, .btn-delete {
    background-color: #E70013;
    border-color: #E70013;
    color: white;
}

.btn-success, .btn-reset {
    background-color: #3fc342;
    border-color: #3fc342;
    color: white;
}

.btn-gray-violet {
    background-color: var(--gray-violet);
    border-color: var(--gray-violet);
    color: white;
}
.table-border-bottom,
.table-border {
    border-collapse: collapse;
}

.table-border th,
.table-border td {
    border: 1px solid var(--border);
    padding: 5px;
}
.table-border-bottom th,
.table-border-bottom td{
    border-bottom: 1px solid var(--border);
}

.table-sm tr,
.table-sm th{
    padding: 5px!important;
}



/**/